import optionsDefault from './optionsDefault';
import amwellClaims from './amwellClaims';
import amwellDirectInvoice from './amwellDirectInvoice';

const templates = {
  'No Template': optionsDefault,
  'Amwell (Claims)': amwellClaims,
  'Amwell (Direct Invoice)': amwellDirectInvoice,
};

export default templates;
