<template>
  <b-modal
  size="lg"
  centered
  :id="id"
  :title="translations.delete_modal_title"
  @hidden="hide"
  @ok="onConfirm">
    <p class="my-2">{{ translations.delete_modal_message }}</p>

    <div class="table-responsive mt-3">
      <table class="table">
        <thead>
          <tr>
            <th>{{ translations.transaction_type }}</th>
            <th>{{ translations.unit }}</th>
            <th>{{ translations.service_type }}</th>
            <th>{{ translations.applicable_to }}</th>
            <th>{{ translations.transaction_value }}</th>
            <th>{{ translations.billing_configuration_id }}</th>
            <th>{{ translations.cpt_code }}</th>
            <th>{{ translations.modifier }}</th>
            <th>{{ translations.rendering_provider }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ formatters.formatArrayString(selectedConfig.transaction_types) }}</td>
            <td>{{ formatters.formatArrayString(selectedConfig.units) }}</td>
            <td>{{ formatters.formatServiceTypeText(selectedConfig.service_types) }}</td>
            <td>{{ formatters.formatArrayString(selectedConfig.applicable_to) }}</td>
            <td>{{ selectedConfig.transaction_value || '-' }}</td>
            <td>{{ formatters.formatArrayString(selectedConfig.billing_configuration_ids) }}</td>
            <td>{{ selectedConfig.cpt_code }}</td>
            <td>{{ selectedConfig.modifier || '-' }}</td>
            <td>{{ formatters.formatRendingProviderText(selectedConfig.rendering_provider) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" @click="cancel()">
        Cancel
      </b-button>
      <b-button size="sm" variant="danger" @click="ok()">
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { cloneDeep } from 'lodash';
import { parseResponseError } from '@/http/parsers/parse_response';
import translations from '@/translations';
import * as formatters from './shared/formatters';

export default {
  name: 'ModalDeleteConfig',
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formatters,
      translations: translations.finance.payers.client_payer_configs,
      id: 'modal-delete-config',
      selectedConfig: {},
    };
  },
  methods: {
    show(config) {
      this.selectedConfig = config;
      this.$bvModal.show(this.id);
    },
    hide() {
      this.selectedConfig = {};
      this.$bvModal.hide(this.id);
    },
    async onConfirm() {
      const selectedClone = cloneDeep(this.selectedConfig);
      if (!selectedClone.id) {
        // Delete local config
        this.$emit('payer-config-deleted', selectedClone);
        this.hide();
        return;
      }

      try {
        await this.$store.dispatch('Financial/deleteClientPayerConfiguration', {
          clientId: this.clientId,
          payerId: selectedClone.payer_id,
          configId: selectedClone.id,
        });
        this.$emit('payer-config-deleted', selectedClone);
        this.$noty.success(this.translations.delete_feedback_success);
      } catch (err) {
        this.$noty.error(`${this.translations.delete_feedback_error}: ${parseResponseError(err)}`);
      } finally {
        this.hide();
      }
    },
  },
};
</script>
