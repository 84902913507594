export default {
  billing_model_id: null,
  rules_ids: [],
  billing_units: [],
  with_predict: false,
  with_cbt: false,
  with_ect: false,
  service_type: null,
  aso_groups: [],
  start_date: null,
  enrollment_fee: null,
  three_sessions_fee: null,
  nine_sessions_fee: null,
  pain_score_reduction_fee: null,
  full_fee: null,
  session_fee: null,
  session_cap: null,
  last_x_days: null,
  pepm_fee: null,
  pepm_fee_calculation: null,
  pepm_eligible_population: null,
  pepm_employee_count: null,
  move_discount: null,
  not_chargeable_health_plans: [],
  currency: null,
  statements_configurations: [],
  revenue_shares: [],
  price_escalator: {
    price_escalator_start_date: null,
    price_escalator_interval: null,
    price_escalator_percentage: null,
  },
};
