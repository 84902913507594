// Custom Model - Humana ASO [50/25/25]
export default {
  billing_model_id: 4,
  rules_ids: [ 12, 13, 20, 24 ],
  billing_units: [ 'sword' ],
  enrollment_fee: 500,
  full_fee: 1000,
  currency: 'USD',
  statements_configurations: [
    {
      billing_type: 'Direct Invoice',
      billing_period: 'Monthly',
      billing_provider_id: 4,
    },
  ],
  revenue_shares: [
    {
      partnership_id: 2, // Amwell
      sharing: true,
      calculation: 'percentage_transactions',
      calculation_value: 15,
      billing_mode: 'credit_invoice',
      treatment: 'selling_expense',
    },
    {
      partnership_id: 8, // Anthem
      sharing: true,
      calculation: 'flat_fee_enrollment',
      calculation_value: 100,
      billing_mode: 'credit_invoice',
      treatment: 'selling_expense',
    },
  ],
  payers: [],
};
