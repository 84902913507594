<template>
  <div>
    <b-spinner v-if="loading"></b-spinner>
    <b-col v-else>
      <table class="table mb-0" data-id="list-payers">
        <tr>
          <th scope="col">{{ translations.form.payer_name }}</th>
          <th scope="col">{{ translations.form.payer_id }}</th>
          <th scope="col">{{ translations.form.address }}</th>
          <th scope="col" class="btnColWidth">{{ translations.form.actions }}</th>
        </tr>
        <tbody>
        <tr v-for="(item, index) in apiPayers" :key="index">
          <td>{{ item.payer_name }}</td>
          <td>{{ item.payer_external_id }}</td>
          <td>{{ payerAddressSummary(item) }}</td>
          <td class="btnColWidth">
            <b-button
              @click="onEditBtn(item)"
              size="sm"
              variant="secondary"
              class="col-2 mr-1 btnWidth">
              <feather type="edit-2"></feather>
            </b-button>
            <b-button
              @click="onDeleteBtn(item)"
              size="sm"
              variant="secondary"
              class="col-2 btnWidth">
              <feather type="trash"></feather>
            </b-button>
          </td>
        </tr>
        <tr>
          <td v-if="apiPayers < 1" colspan="3" class="text-center text-muted">{{ translations.form.no_payers }}</td>
          <td v-else colspan="3"/>
          <td class="btnColWidth">
            <b-button
              @click="onAddBtn"
              size="sm"
              variant="secondary"
              class="col-2 btnWidth">
              <feather type="plus"></feather>
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </b-col>
    <div>
      <b-modal
        data-id="payer-form-modal"
        ref="payer-form-modal"
        :title="modalTitle"
        size="xl"
        :ok-title="modalOkBtn"
        @ok="onModalOKBtn"
        @hidden="resetData"
        static
        :busy="doingButtonRequest">
        <b-row id="payer-form-fields" class="d-flex align-items-center">
          <b-col md="3" sm="12">
            <b-form-group
              label-class="p-0"
              :label="translations.form.system_payer_id"
              :invalid-feedback="handleError('payerForm.system_payer_id')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.system_payer_id')">
              <b-select
                v-model="payerForm.system_payer_id"
                :options="apiSystemPayers"/>
            </b-form-group>
          </b-col>
          <b-col md="3" sm="12">
            <b-form-group
              label-class="p-0"
              :label="translations.form.place_of_service"
              :invalid-feedback="handleError('payerForm.place_of_service')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.place_of_service')">
              <b-select
                v-model="payerForm.place_of_service"
                :options="formatPlaceOfServiceOptions()"/>
            </b-form-group>
          </b-col>
          <b-col md="3" sm="12">
            <b-form-group
              label-class="p-0"
              :label="translations.form.filing"
              :invalid-feedback="handleError('payerForm.filing')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.filing')">
              <b-select
                v-model="payerForm.filing"
                :options="formatFilingOptions()"/>
            </b-form-group>
          </b-col>
          <b-col md="3" sm="12">
            <b-form-group
              label-class="p-0"
              :label="translations.form.group_number"
              :invalid-feedback="handleError('payerForm.group_number')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.group_number')">
              <b-select
                v-model="payerForm.group_number"
                :options="formatGroupNumberOptions()"/>
            </b-form-group>
          </b-col>
          <b-col md="3" sm="12">
            <label>
              <toggle-button
                data-id="toggle-dos-range"
                v-model="payerForm.enrollment_dos_range"
                name="toggle-dos-range"
                sync/>
              {{ translations.form.enrollment_dos_range }}
            </label>
          </b-col>
        </b-row>
        <div class="table-responsive mt-3">
          <table class="table table-stripped" data-id="list-client-payer-configs">
            <thead>
              <tr>
                <th>{{ translations.client_payer_configs.transaction_type }}</th>
                <th>{{ translations.client_payer_configs.unit }}</th>
                <th>{{ translations.client_payer_configs.service_type }}</th>
                <th>{{ translations.client_payer_configs.applicable_to }}</th>
                <th>{{ translations.client_payer_configs.transaction_value }}</th>
                <th>{{ translations.client_payer_configs.billing_configuration_id }}</th>
                <th>{{ translations.client_payer_configs.cpt_code }}</th>
                <th>{{ translations.client_payer_configs.modifier }}</th>
                <th>{{ translations.client_payer_configs.rendering_provider }}</th>
                <th class="text-center">{{ translations.client_payer_configs.actions }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
              v-for="(item, idx) in payerForm.client_payer_configurations"
              :key="idx"
              :class="{'text-danger': !item.id && newClientPayerConfigIsDuplicated}">
                <td>{{ formatters.formatArrayString(item.transaction_types) }}</td>
                <td>{{ formatters.formatArrayString(item.units) }}</td>
                <td>{{ formatters.formatServiceTypeText(item.service_types) }}</td>
                <td>{{ formatters.formatArrayString(item.applicable_to) }}</td>
                <td>{{ item.transaction_value || '-' }}</td>
                <td>{{ formatters.formatArrayString(item.billing_configuration_ids) }}</td>
                <td>
                  <div v-if="clientPayerConfigLineEditing !== idx">
                    {{ item.cpt_code }}
                  </div>
                  <div v-else>
                    <b-select
                    :options="formatCPTCodeOptions()"
                    v-model="item.cpt_code"/>
                  </div>
                </td>
                <td>
                  <div v-if="clientPayerConfigLineEditing !== idx">
                    {{ item.modifier || '-' }}
                  </div>
                  <div v-else>
                    <b-select
                    :options="formatModifierOptions()"
                    v-model="item.modifier"/>
                  </div>
                </td>
                <td>
                  <div v-if="clientPayerConfigLineEditing !== idx">
                    {{ formatters.formatRendingProviderText(item.rendering_provider) }}
                  </div>
                  <div v-else>
                    <b-select
                    :options="formatters.formatRenderingProviderOptions()"
                    v-model="item.rendering_provider"/>
                  </div>
                </td>
                <td class="d-flex justify-content-center align-items-center flex-nowrap">
                  <b-button
                    v-if="clientPayerConfigLineEditing !== idx"
                    @click="onEditClientPayerConfig(idx)"
                    size="sm"
                    variant="secondary">
                    <feather type="edit-2"></feather>
                  </b-button>
                  <b-button
                    v-if="!item.is_default"
                    @click="handleDeleteClientPayerConfig(item)"
                    class="ml-2"
                    size="sm"
                    variant="outline-danger">
                    <feather type="x"></feather>
                  </b-button>
                </td>
              </tr>
              <tr>
                <td style="min-width: 12rem;">
                  <b-form-group
                  :invalid-feedback="handleError('newClientPayerConfig.transaction_types')"
                  :state="shouldValidateNewClientConfig && isValid('newClientPayerConfig.transaction_types')">
                    <multiselect
                    multiple
                    placeholder=""
                    :showPointer="false"
                    :allow-empty="false"
                    v-model="newClientPayerConfig.transaction_types"
                    :options="constants.TRANSACTION_TYPES"/>
                  </b-form-group>
                </td>
                <td style="min-width: 8rem;">
                  <b-form-group
                  :invalid-feedback="handleError('newClientPayerConfig.units')"
                  :state="shouldValidateNewClientConfig && isValid('newClientPayerConfig.units')">
                  <multiselect
                    multiple
                    placeholder=""
                    :showPointer="false"
                    :allow-empty="false"
                    v-model="newClientPayerConfig.units"
                    :options="constants.UNIT_OPTIONS"/>
                  </b-form-group>
                </td>
                <td style="min-width: 8rem;">
                  <b-form-group
                  :invalid-feedback="handleError('newClientPayerConfig.service_types')"
                  :state="shouldValidateNewClientConfig && isValid('newClientPayerConfig.service_types')">
                    <multiselect
                    multiple
                    track-by="value"
                    label="text"
                    placeholder=""
                    :showPointer="false"
                    :allow-empty="false"
                    v-model="newClientPayerConfig.service_types_selected"
                    :options="formatters.formatServiceTypeOptions()"
                    @input="onChangeServiceTypes"/>
                  </b-form-group>
                </td>
                <td style="min-width: 8rem;">
                  <b-form-group
                  :invalid-feedback="handleError('newClientPayerConfig.applicable_to')"
                  :state="shouldValidateNewClientConfig && isValid('newClientPayerConfig.applicable_to')">
                  <multiselect
                    multiple
                    placeholder=""
                    :showPointer="false"
                    :allow-empty="false"
                    v-model="newClientPayerConfig.applicable_to"
                    :options="constants.APPLICABLE_TO"/>
                  </b-form-group>
                </td>
                <td style="min-width: 8rem;">
                  <b-form-group
                  :invalid-feedback="handleError('newClientPayerConfig.transaction_value')"
                  :state="shouldValidateNewClientConfig && isValid('newClientPayerConfig.transaction_value')">
                    <b-select
                    v-model="newClientPayerConfig.transaction_value"
                    :options="formatTransactionValueOptions()"/>
                  </b-form-group>
                </td>
                <td style="min-width: 14rem;">
                  <b-form-group
                  :invalid-feedback="handleError('newClientPayerConfig.billing_configuration_ids')"
                  :state="shouldValidateNewClientConfig && isValid('newClientPayerConfig.billing_configuration_ids')">
                    <multiselect
                    multiple
                    track-by="value"
                    label="text"
                    placeholder=""
                    :showPointer="false"
                    v-model="newClientPayerConfig.billing_configuration_ids_selected"
                    :options="formatBillingConfigOptions()"
                    @input="onChangeBillingConfigIDs"/>
                  </b-form-group>
                </td>
                <td style="min-width: 8rem;">
                  <b-form-group
                  :invalid-feedback="handleError('newClientPayerConfig.cpt_code')"
                  :state="shouldValidateNewClientConfig && isValid('newClientPayerConfig.cpt_code')">
                    <b-select
                    v-model="newClientPayerConfig.cpt_code"
                    :options="formatCPTCodeOptions()"/>
                  </b-form-group>
                </td>
                <td style="min-width: 8rem;">
                  <b-form-group
                  :invalid-feedback="handleError('newClientPayerConfig.modifier')"
                  :state="shouldValidateNewClientConfig && isValid('newClientPayerConfig.modifier')">
                    <b-select
                    v-model="newClientPayerConfig.modifier"
                    :options="formatModifierOptions()"/>
                  </b-form-group>
                </td>
                <td style="min-width: 8rem;">
                  <b-form-group
                  :invalid-feedback="handleError('newClientPayerConfig.rendering_provider')"
                  :state="shouldValidateNewClientConfig && isValid('newClientPayerConfig.rendering_provider')">
                    <b-select
                    v-model="newClientPayerConfig.rendering_provider"
                    :options="formatters.formatRenderingProviderOptions()"/>
                  </b-form-group>
                </td>
                <td class="text-center">
                  <b-button
                    @click="onCreateClientPayerConfig()"
                    size="sm"
                    variant="secondary">
                    <feather type="plus"></feather>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
    </div>
    <modal-delete-config
    ref="modal-delete-config"
    :client-id="clientId"
    @payer-config-deleted="onDeleteClientPayerConfig"/>
  </div>
</template>

<script>
import {
  required, maxLength, alphaNum, and, minValue,
} from 'vuelidate/lib/validators';
import { cloneDeep, isEqual } from 'lodash';
import Multiselect from 'vue-multiselect';

import errorHandler from '@/mixins/errorHandler';
import translations from '@/translations';
import { parseResponseError } from '@/http/parsers/parse_response';
import * as constants from '@/constants/finance';
import * as formatters from './shared/formatters';
import ModalDeleteConfig from './ModalDeleteConfig.vue';

export default {
  name: 'Payers',
  components: {
    Multiselect,
    ModalDeleteConfig,
  },
  mixins: [ errorHandler ],
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    billingConfigs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      constants,
      formatters,
      translations: translations.finance.payers,
      loading: true,
      apiPayers: [],
      defaultClientPayerConfigurations: [],
      apiSystemPayers: [],
      doingButtonRequest: false,
      shouldValidateNewPayerForm: null, // null instead of false! because "null && false" is null and not false
      shouldValidateNewClientConfig: null,
      newClientPayerConfigIsDuplicated: false,
      clientPayerConfigLineEditing: null,
      payerForm: {
        client_payer_configurations: [],
      },
      newClientPayerConfig: {
        transaction_types: [],
        units: [],
        service_types: [],
        applicable_to: [],
        billing_configuration_ids: [],
        billing_configuration_ids_selected: [], // for multiselect
        service_types_selected: [], // for multiselect
      },
    };
  },
  validations() {
    return {
      payerForm: {
        system_payer_id: {
          required: and(required, minValue(1)),
          'Payer already used': value => {
            if (!value) {
              return true;
            }
            if (this.payerForm?.id) {
              return !this.apiPayers.find(payerElem => payerElem.system_payer_id === value && this.payerForm.id !== payerElem?.id);
            }
            return !this.apiPayers.find(payerElem => payerElem.system_payer_id === value);
          },
        },
        place_of_service: { alphaNum, maxLength: maxLength(3) },
        filing: { alphaNum, maxLength: maxLength(2) },
        group_number: { maxLength: maxLength(20) },
      },
      newClientPayerConfig: {
        transaction_types: { required },
        units: { required },
        service_types: { required },
        applicable_to: { required },
        cpt_code: { required },
      },
    };
  },
  async beforeMount() {
    this.loading = true;
    await this.loadPayers();
    await this.loadSystemPayers();
    this.loading = false;
  },
  computed: {
    modalTitle() {
      return this.payerForm?.id ? this.translations.form.edit_modal_title : this.translations.form.add_modal_title;
    },
    modalOkBtn() {
      return this.payerForm?.id ? this.translations.form.edit_button : this.translations.form.add_button;
    },
  },
  methods: {
    async loadSystemPayers() {
      try {
        const payers = await this.$store.dispatch('Financial/getSystemPayers');
        if (payers?.data?.data?.system_payers) {
          this.apiSystemPayers = payers.data.data.system_payers.map(p => ({
            value: p.id,
            text: `${p.payer_name} (ID: ${p.claims_payer_id})`,
          }));
        }
      } catch (err) {
        let errDetail = err;
        if (err?.response?.data?.error?.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `An error occurred fetching payers: ${errDetail}`;
        this.$noty.error(errMsg);
      }
    },
    async loadPayers() {
      try {
        const { data: { data } } = await this.$store.dispatch('Financial/getPayers', this.clientId);
        this.apiPayers = data.payers;
        this.defaultClientPayerConfigurations = data.default_client_payer_configs;
        this.emitData();
      } catch (err) {
        this.$noty.error(
          `An error occurred fetching payers: ${parseResponseError(err)}`,
        );
      }
    },
    async onDeleteBtn(item) {
      if (!await this.$bvModal.msgBoxConfirm(this.translations.form.delete_confirm, {
        title: item.payer_name,
      })) {
        return;
      }

      try {
        this.doingButtonRequest = true;
        await this.$store.dispatch('Financial/deletePayer', { clientId: this.clientId, payerId: item.id });
        this.apiPayers = this.apiPayers.filter(payer => payer.id !== item.id);
        this.$noty.success(this.translations.form.delete_success);
        this.emitData();
      } catch (err) {
        this.$noty.error(`${this.translations.form.delete_error}: ${parseResponseError(err)}`);
      } finally {
        this.doingButtonRequest = false;
      }
    },
    async addPayer(payer) {
      try {
        this.newClientPayerConfigIsDuplicated = false;
        this.doingButtonRequest = true;
        const { data } = await this.$store.dispatch('Financial/addPayer', {
          clientId: this.clientId,
          payerData: payer,
        });

        this.apiPayers.push(data.data);
        this.$noty.success(this.translations.form.add_success);
        this.emitData();
        return true;
      } catch (err) {
        const errParsed = parseResponseError(err);
        if (errParsed.includes('duplicate configuration')) {
          this.newClientPayerConfigIsDuplicated = true;
          this.$noty.error('Each configuration combination must be unique, please review the configs');
          return false;
        }

        this.$noty.error(`${this.translations.form.add_error}: ${errParsed}`);
        return false;
      } finally {
        this.doingButtonRequest = false;
      }
    },
    async editPayer() {
      try {
        this.newClientPayerConfigIsDuplicated = false;
        this.doingButtonRequest = true;
        const { data } = await this.$store.dispatch('Financial/editPayer', {
          clientId: this.clientId,
          payerId: this.payerForm.id,
          payerData: this.payerForm,
        });

        const index = this.apiPayers.findIndex(p => p.id === this.payerForm.id);
        if (index !== -1) {
          this.apiPayers[index] = data.data;
        }
        this.$noty.success(this.translations.form.edit_success);
        this.emitData();
        return true;
      } catch (err) {
        const errParsed = parseResponseError(err);
        if (errParsed.includes('duplicate configuration')) {
          this.newClientPayerConfigIsDuplicated = true;
          this.$noty.error('Each configuration combination must be unique, please review the configs');
          return false;
        }

        this.$noty.error(`${this.translations.form.edit_error}: ${errParsed}`);
        return false;
      } finally {
        this.doingButtonRequest = false;
      }
    },
    resetData() {
      this.payerForm = {
        system_payer_id: null,
        place_of_service: this.formatPlaceOfServiceOptions()[2].value,
        filing: this.formatFilingOptions()[1].value,
        group_number: this.formatGroupNumberOptions()[0].value,
        enrollment_dos_range: false,
      };

      this.newClientPayerConfig = {
        transaction_types: [],
        units: [],
        service_types: [],
        applicable_to: [],
        billing_configuration_ids: [],
        billing_configuration_ids_selected: [],
        service_types_selected: [],
      };

      this.shouldValidateNewPayerForm = null;
      this.clientPayerConfigLineEditing = null;
      this.shouldValidateNewClientConfig = null;
      this.newClientPayerConfigIsDuplicated = false;
    },
    loadPayerFormWith(payer) {
      this.payerForm = {
        id: payer.id,
        system_payer_id: payer.system_payer_id,
        place_of_service: payer.place_of_service,
        filing: payer.filing,
        group_number: payer.group_number,
        enrollment_dos_range: payer.enrollment_dos_range,
        client_payer_configurations: this.getClientPayerConfigurations(payer),
      };
    },
    toUpperCase(value) {
      if (value) {
        return value.toUpperCase();
      }
      return value;
    },
    formatPlaceOfServiceOptions() {
      return [ {
        value: '',
        text: translations.finance.payers.place_of_service.empty,
      }, {
        value: '02',
        text: translations.finance.payers.place_of_service.telehealth,
      }, {
        value: '10',
        text: translations.finance.payers.place_of_service.telehealth_patient_home,
      }, {
        value: '11',
        text: translations.finance.payers.place_of_service.office,
      } ];
    },
    formatModifierOptions() {
      return [
        {
          value: '',
          text: translations.finance.payers.modifier.empty,
        },
        {
          value: 'GT',
          text: translations.finance.payers.modifier.interactive_video_telecommunications_systems,
        },
        {
          value: '95',
          text: translations.finance.payers.modifier.synchronous_telemedicine_service,
        },
        {
          value: '32',
          text: translations.finance.payers.modifier.amwell_only,
        },
        {
          value: 'GQ',
          text: translations.finance.payers.modifier.async_telecommunications_systems,
        },
        {
          value: 'GP',
          text: translations.finance.payers.modifier.physical_therapy_plan_of_care,
        },
        {
          value: 'GO',
          text: translations.finance.payers.modifier.go,
        },
        {
          value: 'X2',
          text: translations.finance.payers.modifier.x2,
        },
      ];
    },
    formatFilingOptions() {
      return [ {
        value: '',
        text: translations.finance.payers.filing.empty,
      }, {
        value: 'CI',
        text: translations.finance.payers.filing.commercial_insurance,
      }, {
        value: 'BL',
        text: translations.finance.payers.filing.blue_cross,
      } ];
    },
    formatGroupNumberOptions() {
      return [ {
        value: '',
        text: translations.finance.payers.group_number.empty,
      }, {
        value: 'group_policy_number',
        text: translations.finance.payers.group_number.group_policy_number,
      } ];
    },
    formatCPTCodeOptions() {
      return [
        { value: 'S0315', text: 'S0315' },
        { value: 'S0317', text: 'S0317' },
        { value: 'S9451', text: 'S9451' },
        { value: '98975', text: '98975' },
        { value: '98980', text: '98980' },
        { value: '97161', text: '97161 (PT Provider)' },
        { value: '97164', text: '97164 (PT Provider)' },
      ];
    },
    formatTransactionValueOptions() {
      return [
        { value: '', text: '(empty)' },
        { value: 'FullFee/2', text: 'FullFee/2' },
        { value: 'FullFee/4', text: 'FullFee/4' },
      ];
    },
    formatBillingConfigOptions() {
      const opts = [];
      this.billingConfigs.filter(bc => [ 'active', 'inactive' ].includes(bc.status)).forEach(bc => {
        opts.push({ value: bc.id, text: `ID ${bc.id}: ${bc.billing_model?.name}` });
      });
      return opts;
    },
    onChangeBillingConfigIDs(opts) {
      if (!opts?.length) return;
      this.newClientPayerConfig.billing_configuration_ids = opts.map(t => t.value);
    },
    onChangeServiceTypes(opts) {
      if (!opts?.length) return;
      this.newClientPayerConfig.service_types = opts.map(t => t.value);
    },
    payerAddressSummary(item) {
      const fields = [ item.address_line1, item.address_city, item.address_state,
        item.address_postal_code, item.address_country_code ];
      return fields.filter(e => !!e).join(', ');
    },
    onAddBtn() {
      this.shouldValidateNewPayerForm = null;
      this.resetData();
      this.payerForm.client_payer_configurations = this.getClientPayerConfigurations();
      this.$refs['payer-form-modal'].show();
    },
    onEditBtn(item) {
      this.shouldValidateNewPayerForm = null;
      this.loadPayerFormWith(cloneDeep(item));
      this.$refs['payer-form-modal'].show();
    },
    async onModalOKBtn(bvModalEvent) {
      bvModalEvent.preventDefault(); // manually close after success

      this.shouldValidateNewPayerForm = true;
      if (this.$v.payerForm.$invalid) {
        return;
      }

      let success = false;
      if (this.payerForm?.id) {
        success = await this.editPayer();
      } else {
        success = await this.addPayer(this.payerForm);
      }
      if (success) {
        this.resetData();
        this.$refs['payer-form-modal'].hide();
      }
    },
    onEditClientPayerConfig(idx) {
      this.clientPayerConfigLineEditing = idx;
    },
    handleDeleteClientPayerConfig(item) {
      this.$refs['modal-delete-config'].show(item);
    },
    onDeleteClientPayerConfig(item) {
      const itemIdx = this.payerForm.client_payer_configurations.findIndex(c => (item.id && c.id === item.id) || isEqual(c, item));
      if (itemIdx !== -1) {
        this.payerForm.client_payer_configurations.splice(itemIdx, 1);
        this.$forceUpdate();
      }
    },
    onCreateClientPayerConfig() {
      this.shouldValidateNewClientConfig = true;
      if (this.$v.newClientPayerConfig.$invalid) {
        return;
      }

      const newConfig = cloneDeep(this.newClientPayerConfig);
      delete newConfig.billing_configuration_ids_selected; // remove the multiselect data
      delete newConfig.service_types_selected; // remove the multiselect data
      this.payerForm.client_payer_configurations.push(newConfig);
      this.newClientPayerConfig = {
        transaction_types: [],
        units: [],
        applicable_to: [],
      };
      this.shouldValidateNewClientConfig = null;
    },
    emitData() {
      this.$emit('data', JSON.parse(JSON.stringify(this.apiPayers)));
    },
    getClientPayerConfigurations(payer) {
      if (!payer?.client_payer_configurations?.length) {
        return cloneDeep(this.defaultClientPayerConfigurations);
      }

      return payer.client_payer_configurations;
    },
  },
};
</script>
<style lang="scss" scoped>
.btnWidth { min-width: 60px; max-width: 60px; }
.btnColWidth { width: 150px; }
</style>
