<template>
  <b-card header-class="font-weight-bold" border-variant="secondary" header-border-variant="light">
    <template #header>
      <div class="d-flex align-items-center justify-content-between">
        {{ translations.title }}
        <b-button variant="primary" size="sm" :disabled="saving" @click="saveClientConfigs">
          <feather type="save"></feather> {{ translations.save }}
        </b-button>
      </div>
    </template>
    <b-spinner v-if="loading"></b-spinner>
    <div v-else>
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group
              :label="translations.billing_date_label"
              label-for="select-billing-date-config">
              <b-tooltip
                target="select-billing-date-config"
                triggers="hover"
                placement="right"
                custom-class="dark-tooltip">
                {{ translations.billing_date_help }}
              </b-tooltip>
              <b-form-select
                id="select-billing-date-config"
                v-model="clientConfiguration.billing_date_event"
                :options="getBillingDateConfigurationOptions()"/>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-bind:class="{ customTransitionsColDisabled: !customTransitionsEnabled }">
            <label>{{ translations.custom_transitions_table.title }}:</label>
            <table class="table">
              <tr>
                <th scope="col">
                  {{ translations.custom_transitions_table.col_transition_date }}
                  <feather id="col_transition_date_help_icon" class="text-info" type="info"/>
                  <b-tooltip target="col_transition_date_help_icon">
                    {{ translations.custom_transitions_table.col_transition_date_help }}
                  </b-tooltip>
                </th>
                <th scope="col">
                  {{ translations.custom_transitions_table.col_billing_config }}
                  <feather id="col_billing_config_help_icon" class="text-info" type="info"/>
                  <b-tooltip target="col_billing_config_help_icon">
                    {{ translations.custom_transitions_table.col_billing_config_help }}
                  </b-tooltip>
                </th>
                <th scope="col">{{ translations.custom_transitions_table.col_units }}</th>
                <th scope="col" class="btnColWidth">{{ translations.custom_transitions_table.col_actions }}</th>
              </tr>
              <tbody>
              <tr v-for="(item, index) in clientConfiguration.custom_transitions" :key="index">
                <td>{{ item.transition_date }}</td>
                <td>{{ billingConfigLabelById(item.billing_configuration_id) }}</td>
                <td>{{ unitsToStr(item.units) }}</td>
                <td class="btnColWidth">
                  <b-button
                    :disabled="!customTransitionsEnabled"
                    @click="onDeleteCustomTransitionBtn(index)"
                    size="sm"
                    variant="secondary"
                    class="col-2 btnWidth">
                    <feather type="trash"/>
                  </b-button>
                </td>
              </tr>
              <tr v-if="clientConfiguration.custom_transitions && clientConfiguration.custom_transitions.length < 1">
                <td colspan="4" class="text-center text-muted">{{ translations.custom_transitions_table.no_items }}
                  <div v-if="clientConfigurationFormValidate && !isValid('clientConfiguration.custom_transitions')"
                       class="invalid-feedback text-right d-block pr-3 m-0">
                    {{ handleError('clientConfiguration.custom_transitions') }}
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <b-form-group
                    :invalid-feedback="handleError('newCustomTransitionForm.transition_date')"
                    :state="!newCustomTransitionFormValidate || isValid('newCustomTransitionForm.transition_date')">
                    <VueCtkDateTimePicker
                      format="YYYY-MM-DD"
                      id="new_custom_transition_form_datepicker"
                      label="Click to choose date"
                      formatted="ll"
                      :no-button-now="true"
                      :only-date="true"
                      :disabled="!customTransitionsEnabled"
                      v-model="$v.newCustomTransitionForm.transition_date.$model"/>
                  </b-form-group>
                </td>
                <td>
                  <b-form-group
                    label-class="p-0"
                    :invalid-feedback="handleError('newCustomTransitionForm.billing_configuration_id')"
                    :state="!newCustomTransitionFormValidate || isValid('newCustomTransitionForm.billing_configuration_id')">
                    <b-select
                      :disabled="!customTransitionsEnabled || !newCustomTransitionForm.transition_date"
                      v-model="$v.newCustomTransitionForm.billing_configuration_id.$model"
                      :options="formatBillingConfigOptions(billingConfigs, newCustomTransitionForm.transition_date)"/>
                  </b-form-group>
                </td>
                <td>
                  <b-form-group
                    label-class="p-0"
                    :invalid-feedback="handleError('newCustomTransitionForm.units')"
                    :state="!newCustomTransitionFormValidate || isValid('newCustomTransitionForm.units')">
                    {{ unitsToStr($v.newCustomTransitionForm.units.$model) }}
                  </b-form-group>
                </td>
                <td class="btnColWidth">
                  <b-button
                    :disabled="!customTransitionsEnabled"
                    @click="onAddCustomTransitionBtn"
                    size="sm"
                    variant="secondary"
                    class="col-2 btnWidth">
                    <feather type="plus"></feather>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col cols="4">
            <label>
              <toggle-button
                id="checkbox-bill-through-different-client"
                :value="clientConfiguration.is_bill_to_client_required"
                @change="onChangeBillToClientToggle"
                name="checkbox-bill-through-different-client"
                sync/>
              {{ translations.bill_through_a_different_client_label }}
            </label>
          </b-col>
          <b-col cols="4">
            <label>
              <toggle-button
                id="checkbox-invoices-group-by-client"
                v-model="clientConfiguration.invoices_group_by_client"
                name="checkbox-invoices-group-by-client"
                sync/>
              {{ translations.invoices_group_by_client_label }}
            </label>
          </b-col>
          <b-col cols="4">
            <label>
              <toggle-button
                id="checkbox-hold-invoices"
                v-model="clientConfiguration.hold_invoices"
                name="checkbox-hold-invoices"
                sync/>
              {{ translations.hold_invoices_label }}
            </label>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group
              class="p-0 m-0"
              :label="translations.bill_through_client_id_label"
              :invalid-feedback="handleError('clientConfiguration.bill_to_client')"
              :state="isValid('clientConfiguration.bill_to_client')">
              <b-form-input
                type="text"
                v-model="clientConfiguration.bill_to_client"
                :formatter="toUpperCase"
                :disabled="!clientConfiguration.is_bill_to_client_required"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.purchase_order_number_label"
              :invalid-feedback="handleError('clientConfiguration.purchase_order_number')"
              :state="isValid('clientConfiguration.purchase_order_number')"
              :description="translations.purchase_order_number_description">
              <b-form-input
                type="text"
                v-model="clientConfiguration.purchase_order_number"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.terms.label"
              label-for="select-billing-term">
              <b-tooltip
                target="select-billing-term"
                triggers="hover"
                placement="right"
                custom-class="dark-tooltip">
                {{ translations.terms.help }}
              </b-tooltip>
              <b-form-select
                id="select-billing-term"
                v-model="clientConfiguration.terms"
                :options="getTermsOptions()"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group
              :label="translations.ship_to_addressee_label"
              :invalid-feedback="handleError('clientConfiguration.ship_to_addressee')"
              :state="isValid('clientConfiguration.ship_to_addressee')">
              <b-form-input
                type="text"
                v-model="clientConfiguration.ship_to_addressee"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.ship_to_address_label"
              :invalid-feedback="handleError('clientConfiguration.ship_to_address')"
              :state="isValid('clientConfiguration.ship_to_address')">
              <b-form-input
                type="text"
                v-model="clientConfiguration.ship_to_address"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.ship_to_city_label"
              :invalid-feedback="handleError('clientConfiguration.ship_to_city')"
              :state="isValid('clientConfiguration.ship_to_city')">
              <b-form-input
                type="text"
                v-model="clientConfiguration.ship_to_city"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group
              :label="translations.ship_to_state_label"
              :invalid-feedback="handleError('clientConfiguration.ship_to_state')"
              :state="isValid('clientConfiguration.ship_to_state')">
              <b-form-input
                :placeholder="translations.ship_to_state_placeholder"
                :formatter="toUpperCase"
                maxlength="2"
                type="text"
                v-model="clientConfiguration.ship_to_state"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.ship_to_zipcode_label"
              :invalid-feedback="handleError('clientConfiguration.ship_to_zipcode')"
              :state="isValid('clientConfiguration.ship_to_zipcode')">
              <b-form-input
                :placeholder="translations.ship_to_zipcode_placeholder"
                maxlength="9"
                type="text"
                v-model="clientConfiguration.ship_to_zipcode"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.ship_to_country_label"
              :invalid-feedback="handleError('clientConfiguration.ship_to_country')"
              :state="isValid('clientConfiguration.ship_to_country')">
              <b-form-input
                :placeholder="translations.ship_to_country_placeholder"
                :formatter="toUpperCase"
                maxlength="2"
                type="text"
                v-model="clientConfiguration.ship_to_country"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group
              :label="translations.bill_to_addressee_label"
              :invalid-feedback="handleError('clientConfiguration.bill_to_addressee')"
              :state="isValid('clientConfiguration.bill_to_addressee')">
              <b-form-input
                type="text"
                v-model="clientConfiguration.bill_to_addressee"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.bill_to_address_label"
              :invalid-feedback="handleError('clientConfiguration.bill_to_address')"
              :state="isValid('clientConfiguration.bill_to_address')">
              <b-form-input
                type="text"
                v-model="clientConfiguration.bill_to_address"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.bill_to_city_label"
              :invalid-feedback="handleError('clientConfiguration.bill_to_city')"
              :state="isValid('clientConfiguration.bill_to_city')">
              <b-form-input
                type="text"
                v-model="clientConfiguration.bill_to_city"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group
              :label="translations.bill_to_state_label"
              :invalid-feedback="handleError('clientConfiguration.bill_to_state')"
              :state="isValid('clientConfiguration.bill_to_state')">
              <b-form-input
                :placeholder="translations.bill_to_state_placeholder"
                :formatter="toUpperCase"
                maxlength="2"
                type="text"
                v-model="clientConfiguration.bill_to_state"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.bill_to_zipcode_label"
              :invalid-feedback="handleError('clientConfiguration.bill_to_zipcode')"
              :state="isValid('clientConfiguration.bill_to_zipcode')">
              <b-form-input
                :placeholder="translations.bill_to_zipcode_placeholder"
                maxlength="9"
                type="text"
                v-model="clientConfiguration.bill_to_zipcode"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.bill_to_country_label"
              :invalid-feedback="handleError('clientConfiguration.bill_to_country')"
              :state="isValid('clientConfiguration.bill_to_country')">
              <b-form-input
                :placeholder="translations.bill_to_country_placeholder"
                :formatter="toUpperCase"
                maxlength="2"
                type="text"
                v-model="clientConfiguration.bill_to_country"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group :label="translations.email_recipients.label">
              <b-form-tags
                v-model="clientConfiguration.email_recipients"
                :placeholder="translations.email_recipients.placeholder"
                tag-variant="info"
                :invalid-tag-text="translations.email_recipients.invalid_email_message"
                :duplicate-tag-text="translations.email_recipients.duplicate_email_message"
                :tag-validator="emailValidator"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="translations.customer_billing_name"
              :invalid-feedback="handleError('clientConfiguration.customer_billing_name')"
              :state="isValid('clientConfiguration.customer_billing_name')">
              <b-form-input
                type="text"
                v-model="clientConfiguration.customer_billing_name"
                maxlength="255"/>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </div>
  </b-card>
</template>

<script>
import moment from 'moment/moment';
import { cloneDeep } from 'lodash';
import {
  required, requiredIf, maxLength, minLength, alpha, helpers, email,
} from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import translations from '@/translations';
import { formatDate } from '@/helpers/finance';

const billToClientValidator = helpers.regex('partnerKey', /^([A-Z]{2}-[0-9]+)|(STG-.+)$/);

export default {
  name: 'ClientConfigurations',
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    billingConfigs: {
      type: Array,
      required: true,
    },
  },
  mixins: [ errorHandler ],
  data() {
    return {
      translations: translations.finance.client_configs,
      clientConfigurationFormValidate: null,
      clientConfiguration: {
        billing_date_event: '',
        custom_transitions: [],
        is_bill_to_client_required: false,
        invoices_group_by_client: false,
        hold_invoices: false,
        bill_to_client: null,
        purchase_order_number: null,
        ship_to_addressee: null,
        ship_to_address: null,
        ship_to_city: null,
        ship_to_state: null,
        ship_to_zipcode: null,
        ship_to_country: null,
        bill_to_addressee: null,
        bill_to_address: null,
        bill_to_city: null,
        bill_to_state: null,
        bill_to_zipcode: null,
        bill_to_country: null,
        terms: null,
        customer_billing_name: null,
        email_recipients: [],
      },
      customTransitionsEnabled: false,
      newCustomTransitionFormValidate: null,
      newCustomTransitionForm: {
        transition_date: null,
        billing_configuration_id: null,
        units: [],
      },
      loading: true,
      saving: false,
    };
  },
  validations() {
    return this.rules;
  },
  async beforeMount() {
    this.loading = true;
    [ this.clientConfiguration.billing_date_event ] = this.getBillingDateConfigurationOptions();
    await this.loadClientConfigs();
    this.loading = false;
  },
  computed: {
    rules() {
      return {
        clientConfiguration: {
          bill_to_client: {
            requiredIf: requiredIf(elem => elem.is_bill_to_client_required),
            maxLength: maxLength(20),
            'invalid format': billToClientValidator,
          },
          custom_transitions: {
            requiredIf: requiredIf(() => this.customTransitionsEnabled),
          },
          purchase_order_number: {
            maxLength: maxLength(64),
          },
          ship_to_addressee: {
            maxLength: maxLength(100),
          },
          ship_to_address: {
            maxLength: maxLength(100),
          },
          ship_to_city: {
            maxLength: maxLength(100),
          },
          ship_to_state: {
            alpha,
            minLength: minLength(2),
            maxLength: maxLength(2),
          },
          ship_to_zipcode: {
            maxLength: maxLength(9),
          },
          ship_to_country: {
            alpha,
            minLength: minLength(2),
            maxLength: maxLength(2),
          },
          bill_to_addressee: {
            maxLength: maxLength(100),
          },
          bill_to_address: {
            maxLength: maxLength(100),
          },
          bill_to_city: {
            maxLength: maxLength(100),
          },
          bill_to_state: {
            alpha,
            minLength: minLength(2),
            maxLength: maxLength(2),
          },
          bill_to_zipcode: {
            maxLength: maxLength(9),
          },
          bill_to_country: {
            alpha,
            minLength: minLength(2),
            maxLength: maxLength(2),
          },
          customer_billing_name: {
            maxLength: maxLength(255),
          },
        },
        newCustomTransitionForm: {
          transition_date: { required },
          billing_configuration_id: {
            required,
            'Billing configuration must start before "Transition date"': (value, item) => {
              if (!value) {
                return true;
              }

              const billingConfig = this.billingConfigById(value);
              if (!billingConfig) {
                return false;
              }

              const transitionDateMoment = moment.utc(item.transition_date);
              const startDateMoment = moment.utc(billingConfig.start_date);
              return transitionDateMoment.isAfter(startDateMoment);
            },
          },
          units: {
            required,
            'Each unit can only be used once': value => {
              if (!value) {
                return true;
              }
              if (!this.clientConfiguration.custom_transitions) {
                return true;
              }

              const usedUnits = this.clientConfiguration.custom_transitions.map(v => v.units).flat();
              for (let i = 0; i < value.length; ++i) {
                const unit = value[i];
                if (usedUnits.includes(unit)) {
                  return false;
                }
              }
              return true;
            },
          },
        },
      };
    },
  },
  watch: {
    'clientConfiguration.billing_date_event': {
      handler(newVal) {
        this.customTransitionsEnabled = newVal === this.translations.billing_date_options.custom_transition_unbundled
          || newVal === this.translations.billing_date_options.custom_transition_sword_to_bundled_sword_bloom;
        if (!this.customTransitionsEnabled) {
          this.newCustomTransitionFormValidate = null;
          this.resetNewCustomTransitionForm();
        }
      },
    },
    'newCustomTransitionForm.billing_configuration_id': {
      handler(newVal) {
        const config = this.billingConfigs.find(v => v.id === newVal);
        if (config) {
          this.newCustomTransitionForm.units = config.billing_units.map(v => v.name);
        } else {
          this.newCustomTransitionForm.units = [];
        }
      },
    },
  },
  methods: {
    async loadClientConfigs() {
      try {
        const res = await this.$store.dispatch('Financial/getBillingClientConfiguration', this.clientId);
        if (res?.data?.data) {
          const { data } = res.data;
          let emailRecipients = [];
          if (res.data.data?.email_recipients?.length > 0) {
            emailRecipients = res.data.data.email_recipients.split(',');
          }

          this.clientConfiguration = {
            ...data,
            is_bill_to_client_required: !!res.data.data.bill_to_client,
            email_recipients: emailRecipients,
          };
        }
      } catch (err) {
        if (err && err.response && err.response.status === 404) {
          return;
        }
        this.$noty.error(this.translations.errors.fetch_error);
      }
    },
    async saveClientConfigs() {
      this.clientConfigurationFormValidate = true;

      if (this.$v.clientConfiguration.$invalid) {
        return;
      }

      try {
        this.saving = true;
        const { clientId, clientConfiguration } = this;
        const clientConfigurationBody = cloneDeep(clientConfiguration);
        clientConfigurationBody.email_recipients = clientConfigurationBody.email_recipients.toString();

        await this.$store.dispatch('Financial/saveBillingClientConfiguration', { clientId, clientConfiguration: clientConfigurationBody });
        this.$noty.success(this.translations.success.save_success);
        this.clientConfigurationFormValidate = null;
      } catch (error) {
        let errorMsg = this.translations.errors.save_error;
        if (error?.response?.data?.error?.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      } finally {
        this.saving = false;
      }
    },
    getBillingDateConfigurationOptions() {
      return [
        this.translations.billing_date_options.event_date,
        this.translations.billing_date_options.onboarding_date,
        this.translations.billing_date_options.custom_transition_unbundled,
        this.translations.billing_date_options.custom_transition_sword_to_bundled_sword_bloom,
      ];
    },
    onChangeBillToClientToggle({ value }) {
      this.clientConfiguration.is_bill_to_client_required = value;
      if (!value) {
        this.clientConfiguration.bill_to_client = '';
      }
    },
    getTermsOptions() {
      return Object.values(this.translations.terms.options).map(option => {
        const value = option === '' ? null : option;
        return {
          text: option,
          value,
        };
      });
    },
    emailValidator(emailToValidate) {
      if (typeof emailToValidate !== 'string') {
        return false;
      }

      if (!email(emailToValidate)) {
        return false;
      }

      return true;
    },
    toUpperCase(value) {
      return value ? value.toUpperCase() : value;
    },
    onAddCustomTransitionBtn() {
      this.newCustomTransitionFormValidate = true;
      if (this.$v.newCustomTransitionForm.$invalid) {
        return;
      }

      this.newCustomTransitionFormValidate = null;
      const newConfig = JSON.parse(JSON.stringify(this.newCustomTransitionForm));

      this.clientConfiguration.custom_transitions.push(newConfig);
      this.resetNewCustomTransitionForm();
    },
    onDeleteCustomTransitionBtn(index) {
      this.clientConfiguration.custom_transitions.splice(index, 1);
    },
    formatBillingConfigOptions(billingConfigs, transitionDate) {
      const opts = [ {
        text: '',
        value: null,
      } ];

      billingConfigs
        .filter(c => c.status === 'active' || c.status === 'inactive')
        .filter(c => {
          if (transitionDate) {
            const transitionDateMoment = moment.utc(transitionDate);
            const startDateMoment = moment.utc(c.start_date);
            return transitionDateMoment.isAfter(startDateMoment);
          }
          return false;
        })
        .forEach(c => {
          opts.push({
            text: this.billingConfigToStr(c),
            value: c.id,
          });
        });
      return opts;
    },
    resetNewCustomTransitionForm() {
      this.newCustomTransitionForm.transition_date = null;
      this.newCustomTransitionForm.billing_configuration_id = null;
      this.newCustomTransitionForm.units = [];
    },
    unitsToStr(units) {
      if (!units) {
        return '';
      }

      return units.join(', ');
    },
    billingConfigById(id) {
      for (let i = 0; i < this.billingConfigs.length; ++i) {
        const config = this.billingConfigs[i];
        if (config.id === id) {
          return config;
        }
      }
      return undefined;
    },
    billingConfigLabelById(id) {
      const config = this.billingConfigById(id);
      if (config) {
        return this.billingConfigToStr(config);
      }
      return `${id}_ERR_NO_LABEL`;
    },
    billingConfigToStr(billingConfig) {
      const startDate = formatDate(billingConfig.start_date, 'YYYY-MM-DD', '');
      const endDate = formatDate(billingConfig.end_date, 'YYYY-MM-DD', '');
      const dateStr = `${startDate} - ${endDate}`;
      return `ID ${billingConfig.id}: ${billingConfig.billing_model?.name} (${dateStr})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.btnWidth {
  min-width: 60px;
  max-width: 60px;
}

.btnColWidth {
  width: 150px;
}

.customTransitionsColDisabled {
  background-color: #f2f2f2;
}
</style>
