import translations from '@/translations';

export function formatServiceTypeOptions() {
  return [
    { value: 'pt_provider', text: 'PT Provider' },
    { value: 'wellness', text: 'Wellness' },
  ];
}

export function formatRenderingProviderOptions() {
  return [ {
    value: '',
    text: translations.finance.payers.rendering_provider.empty,
  }, {
    value: 'physical_therapist',
    text: translations.finance.payers.rendering_provider.physical_therapist,
  } ];
}

export function formatArrayString(array) {
  if (!array?.length) {
    return '-';
  }
  return array.join(', ');
}

export function formatServiceTypeText(array) {
  if (!array?.length) {
    return '-';
  }
  return array.map(a => formatServiceTypeOptions().find(o => o.value === a)?.text).join(', ');
}

export function formatRendingProviderText(value) {
  if (!value) {
    return '-';
  }

  const opts = formatRenderingProviderOptions();
  const found = opts.find(o => o.value === value);
  return found?.text || value;
}
