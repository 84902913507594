<template>
<div>
  <table class="table table-striped mb-0">
    <thead>
      <tr>
        <th scope="col">{{ translations.id }}</th>
        <th scope="col">{{ translations.start_date }}</th>
        <th scope="col">{{ translations.end_date }}</th>
        <th scope="col">{{ translations.billing_model }}</th>
        <th scope="col">{{ translations.units }}</th>
        <th scope="col">{{ translations.predict }}</th>
        <th scope="col">{{ translations.service_type }}</th>
        <th scope="col">{{ translations.aso_groups }}</th>
        <th scope="col">{{ translations.status }}</th>
        <th scope="col">{{ translations.actions }}</th>
      </tr>
    </thead>
    <tbody v-if="data.length">
      <tr v-for="(item, index) in data" :key="index">
        <td>{{ item.id }}</td>
        <td>{{ formatDate(item.start_date) }}</td>
        <td>{{ formatDate(item.end_date) }}</td>
        <td>{{ item.billing_model.name }}
          <feather v-if="item.by_price_escalator" v-b-tooltip.hover title="created by price escalator"
            type="trending-up">
          </feather>
        </td>
        <td>{{ formatBillingUnits(item.billing_units) }}</td>
        <td>{{ formatPredict(item.with_predict) }}</td>
        <td>{{ formatServiceType(item.service_type) }}</td>
        <td>{{ formatASOGroups(item.aso_groups) }}</td>
        <td>
          <b-badge :variant="formatStatusLabel(item.status)">
            <h6 class="badge-status text-capitalize">{{ item.status }}</h6>
          </b-badge>
        </td>
        <td class="col-2 overflow-initial">
          <b-button v-b-tooltip.hover
                    size="sm"
                    :title="translations.view_details"
                    variant="light"
                    class="col-2 btn-history"
                    :to="`/onboarding/clients/${item.client_id}/billing/history/${item.id}`">
            <feather type="eye"></feather>
          </b-button>
          <b-button v-b-tooltip.hover
                    @click="copyBillingConfig(item)"
                    size="sm"
                    :title="translations.copy_for_edition"
                    variant="light"
                    class="col-2 btn-history">
            <feather type="copy"></feather>
          </b-button>
          <b-button v-b-tooltip.hover
                    v-if="item.status === 'new' || item.status === 'draft'"
                    @click="deleteBillingConfig(item.id)"
                    size="sm"
                    :title="translations.delete"
                    variant="light"
                    class="col-2 btn-history">
            <feather type="trash"></feather>
          </b-button>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td :colspan="9" class="text-center">
          There's no history
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { formatDate } from '@/helpers/finance';
import translations from '@/translations';

export default {
  name: 'ClientHistory',
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      translations: translations.finance.billing_configurations.history,
    };
  },
  methods: {
    formatDate,
    formatStatusLabel(label) {
      if (label === 'active') {
        return 'success';
      }
      if (label === 'inactive') {
        return 'danger';
      }
      return 'secondary';
    },
    async deleteBillingConfig(id) {
      const yes = await this.$bvModal.msgBoxConfirm(this.translations.delete_modal_confirmation, {
        okTitle: this.translations.delete,
      });
      if (!yes) {
        return;
      }

      this.$store.dispatch('Financial/deleteBillingConfiguration', id)
        .then(result => {
          if (result) {
            this.$emit('delete', id);
          }
        });
    },
    async copyBillingConfig(item) {
      this.$emit('copy-billing-config', cloneDeep(item));
    },
    formatBillingUnits(units) {
      if (!Array.isArray(units)) {
        return JSON.stringify(units);
      }
      return units.map(u => u.name).join(', ');
    },
    formatServiceType(serviceType) {
      if (!serviceType) {
        return '-';
      }

      const options = {
        pt_provider: 'PT Provider',
        wellness: 'Wellness',
      };
      if (options[serviceType]) {
        return options[serviceType];
      }
      return serviceType;
    },
    formatASOGroups(asoGroups) {
      if (!asoGroups?.length) {
        return '-';
      }

      const tenants = this.$store.getters['Eligibility/getTenants'];
      const formatted = asoGroups.map(asoGroup => {
        const tenant = tenants?.find(i => i.aso_group === asoGroup);
        if (!tenant) {
          return `(ID: ${asoGroup} deleted)`;
        }

        return `(${tenant.aso_group_name})`;
      });

      return formatted.join(', ');
    },
    formatPredict(predict) {
      if (!predict) {
        return '-';
      }

      return this.translations.yes;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-history {
  min-width: 50px;
  margin-left: 8px;
}

.badge-status {
  margin-bottom: 0;
}
</style>
