<template>
  <b-row class="mt-4 d-flex align-items-center">
    <div class="col-4 col-md-6">
      <b-form-group
        label-class="p-0"
        :label="translations.template_input_label">
        <b-select
          v-model="templateSelected"
          :options="formatTemplateOptions()"/>
      </b-form-group>
    </div>
    <div>
      <b-button @click="applyTemplate"
                id="button-apply-template"
                class="mt-2"
                type="button"
                variant="primary">
        <span>{{ translations.template_button_apply }}</span>
      </b-button>
    </div>
  </b-row>
</template>

<script>
import { cloneDeep } from 'lodash';
import translations from '../../../translations';
import templates from './templates';

export default {
  name: 'BillingTemplate',
  data() {
    return {
      translations: translations.finance.billing_configurations,
      templateSelected: 'No Template',
    };
  },
  methods: {
    formatTemplateOptions() {
      const opts = [];

      Object.keys(templates).forEach(key => {
        if (key === 'No Template') {
          opts.push({ value: key, text: this.translations.template_input_option_default });
        } else {
          opts.push({ value: key, text: key });
        }
      });

      return opts;
    },
    applyTemplate() {
      const template = templates[this.templateSelected];
      if (template) {
        const templateCopy = cloneDeep(template);
        this.$emit('apply-billing-template', templateCopy);
      }
    },
  },
};
</script>
