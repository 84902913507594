// Custom Model - Humana ASO [50/25/25]
export default {
  billing_model_id: 4,
  rules_ids: [ 12, 13, 20, 24 ],
  billing_units: [ 'sword' ],
  enrollment_fee: 500,
  full_fee: 1000,
  currency: 'USD',
  statements_configurations: [
    {
      billing_type: 'Claims (external)',
      billing_period: 'Bi-weekly',
      payer_external_id: '00332OH',
      billing_provider_id: 4,
      generate_claims_batches: false,
      generate_837_file: false,
      generate_external_claims_report: true,
    },
    {
      billing_type: 'Claims (external)',
      billing_period: 'Bi-weekly',
      payer_external_id: '47198',
      billing_provider_id: 4,
      generate_claims_batches: false,
      generate_837_file: false,
      generate_external_claims_report: true,
    },
    {
      billing_type: 'Claims (external)',
      billing_period: 'Bi-weekly',
      payer_external_id: '303',
      billing_provider_id: 4,
      generate_claims_batches: false,
      generate_837_file: false,
      generate_external_claims_report: true,
    },
  ],
  revenue_shares: [
    {
      partnership_id: 2,
      sharing: true,
      calculation: 'percentage_transactions',
      calculation_value: 15,
      billing_mode: 'credit_invoice',
      treatment: 'selling_expense',
    },
    {
      partnership_id: 8,
      sharing: true,
      calculation: 'flat_fee_enrollment',
      calculation_value: 100,
      billing_mode: 'credit_invoice',
      treatment: 'selling_expense',
    },
  ],
  payers: [
    {
      system_payer_id: 25,
      payer_name: 'Anthem BCBS Ohio',
      payer_external_id: '00332OH',
      address_line1: 'P.O. Box 105187',
      address_city: 'Atlanta',
      address_state: 'GA',
      address_postal_code: '303485187',
      address_country_code: 'US',
      place_of_service: '11',
      filing: 'BL',
      group_number: null,
    },
    {
      system_payer_id: 9,
      payer_name: 'Anthem BC California',
      payer_external_id: '47198',
      address_line1: 'P.O. Box 60007',
      address_city: 'Los Angeles',
      address_state: 'CA',
      address_postal_code: '900600007',
      address_country_code: 'US',
      place_of_service: '11',
      filing: 'BL',
      group_number: null,
    },
    {
      system_payer_id: 26,
      payer_name: 'Empire BCBS New York',
      payer_external_id: '303',
      address_line1: 'P.O. Box 1407, Church Street Station',
      address_city: 'New York',
      address_state: 'NY',
      address_postal_code: '100081407',
      address_country_code: 'US',
      place_of_service: '11',
      filing: 'BL',
      group_number: null,
    },
  ],
};
