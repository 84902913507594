<template>
  <div>
    <multiselect class="multiselect"
      :value="selectedRules"
      :placeholder="translations.placeholder"
      :options="availableRules"
      track-by="name"
      label="description"
      :multiple="true"
      :showPointer="false"
      :searchable="false"
      :custom-label="customLabel"
      @select="onSelect"
      @remove="onRemove">
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import translations from '../../translations';

export default {
  name: 'BillingRules',
  components: {
    Multiselect,
  },
  props: {
    billingModel: {
      type: Number,
    },
    rules: {
      type: Array,
    },
  },
  data() {
    return {
      billingModelConfigurations: null,
      translations: translations.finance.billing_configurations.rules,
      selectedRules: [],
      availableRules: [],
    };
  },
  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    billingModel: function (newVal) {
      this.updateAvailableRules(newVal);
      this.emitRulesChanged([], false);
    },
    // eslint-disable-next-line object-shorthand,func-names
    rules: function (newVal) {
      this.updateSelectedRules(newVal);
    },
  },
  async beforeMount() {
    await this.getBillingModelConfigurations();
  },
  methods: {
    async getBillingModelConfigurations() {
      this.billingModelConfigurations = await this.$store.getters['Financial/getBillingModelConfigurations'];
      this.updateAvailableRules(this.billingModel);
      this.updateSelectedRules(this.rules);
    },
    updateAvailableRules(billingModel) {
      const selectedConfig = this.billingModelConfigurations.find(v => v.id === billingModel);
      if (selectedConfig) {
        this.availableRules = selectedConfig.rules.filter(r => r.active);
      } else {
        this.availableRules = [];
      }
    },
    updateSelectedRules(rules) {
      const newSelectedRules = [];
      if (rules) {
        rules.forEach(rule => {
          this.availableRules.forEach(availRule => {
            if (rule === availRule.id) {
              newSelectedRules.push(availRule);
            }
          });
        });
      }

      this.selectedRules = newSelectedRules;
    },
    onSelect(selectedOption) {
      const rulesCopy = this.selectedRules.slice();
      rulesCopy.push(selectedOption);
      this.emitRulesChanged(rulesCopy, true);
    },
    onRemove(removedOption) {
      this.emitRulesChanged(this.selectedRules.filter(r => r.id !== removedOption.id), true);
    },
    emitRulesChanged(newRules, userTriggered) {
      this.$emit('rulesChanged', {
        rules: newRules.map(r => r.id),
        userInteraction: userTriggered,
      });
    },
    customLabel({ name, description }) {
      return `${name} - ${description}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect {
  .multiselect__tag,
  .multiselect__option {
    white-space: normal;
  }
}
</style>
